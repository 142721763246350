import React from 'react';

import styles from './FinancialCalendar.module.scss';

interface Props {
  year?: string;
  events: Array<{
    date: string;
    hour: string;
    labels: string[];
  }>;
}

const FinancialCalendar = ({ year, events }: Props) => {
  return (
    <div className={styles.wrapper} id="financial-calendar">
      <h2>Financial Calendar {year}</h2>
      <table>
        <thead>
          {events.map((event, key) => (
            <tr key={key}>
              <td className={styles.Date}>{event.date}</td>
              <td>{event.hour}</td>
              <td>
                {event.labels.map((label, key) => (
                  <p key={key}>{label}</p>
                ))}
              </td>
            </tr>
          ))}
        </thead>
      </table>
    </div>
  );
};

export default FinancialCalendar;
