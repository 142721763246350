import React from 'react';

import styles from './InvestorCard.module.scss';

const InvestorCard = () => {
  return (
    <div className={styles.wrapper}>
      <h2>Your IR Contact</h2>
      <div>
        <p> Home Shopping Europe GmbH </p>
        <p> Münchener Strasse 101h</p>
        <p>85737 Ismaning </p>
      </div>
      <p>
        <span>Email :</span> <a href="mailto:investors@hse.com">investors@hse.com</a>
      </p>
    </div>
  );
};

export default InvestorCard;
