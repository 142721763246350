import classes from './UIElements.module.scss';
import React, { useEffect, useState, useRef, useCallback } from 'react';

declare type InputType = 'text' | 'password' | 'number' | 'email' | 'url';

declare type StateObject = {
	required: boolean;
	value: string | number;
	error: boolean;
}

interface CInputProps {
	name: string;
	label: string;
	type: InputType;
	required?: boolean;
    value?: string | number;
    changeHandler?: any;
	state: StateObject;
}



export const CInput = (props: CInputProps) => {

	const [focused, setFocused] = useState(false)
	const [value, setValue] = useState('')

	let labelClass;

	if ( focused || value !== '')
		labelClass = `${classes.Label} ${classes.LabelMini}`
	else
		labelClass = classes.Label;

	return (
		<div
			className={ props.state.error ? `${classes.CInput} ${classes.ErrorHighlight}` : classes.CInput}
		>
			<label className={labelClass} >{props.label}{props.state.required && <span>*</span>}</label>
			<input
				className={classes.InputField}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				type={props.type}
				name={props.name}
				onChange={(e) => {
					setValue(e.target.value)
					props.changeHandler && props.changeHandler({...props.state, value: e.target.value, error: false})
				}}
				/>
		</div>
	)
}


interface CSelectProps {
	name: string;
	label: string;
	required?: boolean;
	options: any[];
    changeHandler?: any;
	state: StateObject;
}

export const CSelect = (props: CSelectProps) => {

	const [showList, setshowList] = useState(false);
	const [currentOption, setCurrentOption] = useState(props.label)
	const ref = useRef(null)

	const clickListener = useCallback(
		(e: MouseEvent) => {
			if (!(ref.current! as any).contains(e.target)) {
				setshowList(false)
			}
		}, [ref.current],
	)

	useEffect(() => {
		document.addEventListener('click', clickListener)
		return () => document.removeEventListener('click', clickListener)
	}, [])

	const onClickChange = (val:any, name:string) => {
		setshowList(false)
		setCurrentOption(name)
		props.changeHandler && props.changeHandler({...props.state, value: val, error: false})
	}

	return (
		<div className={classes.CSelect} ref={ref} >
			<div className={props.state.error ? `${classes.Label} ${classes.ErrorHighlight}` : classes.Label} onClick={() => setshowList(!showList)} >
				<label>{currentOption}{props.state.required && <span>*</span>}</label>
				<ArrowDown />
			</div>
			{showList &&
			<div className={classes.SelectList} >
				{props.options && props.options.map((option, key) => (
					<div className={classes.Option} key={key}
					onClick={() => onClickChange(option.value, option.name)}
					>{option.name}</div>
				))}
			</div>
			}
		</div>
	)
}

interface CTextAreaProps {
	name: string;
	text?: string;
	required?: boolean;
	placeholder?: string;
	limit?: number;
    changeHandler?: any;
	state: StateObject;
}

export const CTextArea = (props: CTextAreaProps) => {
	const [limit, setLimit] = useState(props.limit ? props.limit : 300)

	return (
		<div className={ props.state.error ? `${classes.CTextArea} ${classes.ErrorHighlight}` : classes.CTextArea} >
			<textarea
				placeholder={props.placeholder}
				maxLength={props.limit}
				onChange={
					(e) => {
						props.changeHandler && props.changeHandler({...props.state, value: e.target.value, error: false})
						props.limit && setLimit(props.limit - e.target.value.length)
					}
				} >
			</textarea>
			{props.limit &&
				<span className={classes.Count} style={{
					width: `${limit * 100 / props.limit}px`,
					background: limit === 0 ? 'red' : 'teal'
				}} ></span>
			}
			{props.state.required && <span className={classes.Required} >*</span>}
		</div>
	)
}

declare type ButtonType = 'submit' | 'button';
declare type ButtonStyle = 'primary' | 'link' | 'blank' | 'none';

interface CButtonProps {
	name?: string;
	label: string;
	to?: string;
	type?: ButtonType;
	onSubmit?: any;
	onClick?: any;
	buttonStyle?: ButtonStyle;
}

const buttonStyle = {
	'primary' : classes.BPrimary,
	'link' : classes.BLink,
	'blank' : classes.BBlank,
	'none' : classes.BNone,
}

export const CButton = (props: CButtonProps) => {


	return (
		<button className={[classes.CButton, props.buttonStyle && buttonStyle[props.buttonStyle]].join(' ')}
		type={props.type}
		name={props.name}
		onClick={
			() => {
				props.onClick && props.onClick()
			}
		}
		onSubmit={
			() => {
				props.onClick && props.onSubmit()
			}
		}
		 >{props.label}</button>
	)
}

export const CLink = (props: CButtonProps) => {
	return (
		<a
      href={props.to}
      className={[classes.CLink, classes.CButton, props.buttonStyle && buttonStyle[props.buttonStyle]].join(' ')}
      onClick={() => props.onClick()}
    >
			{props.label}
		</a>
	)
}

interface CCheckBoxProps {
	name: string;
	children: JSX.Element;
	checked?: boolean;
	required?: boolean;
    value?: string | number;
    changeHandler?: any;
	state: boolean;
}

export const CCheckBox = (props: CCheckBoxProps) => {
	return (
		<div className={classes.CCheckBox} >
			{
				props.state ? 
				<input type='checkbox' name={props.name} onChange={() => props.changeHandler && props.changeHandler(!props.state)} checked/> 
				:
				<input type='checkbox' name={props.name} onChange={() => props.changeHandler && props.changeHandler(!props.state)} />
			}
			<label>{props.children}</label>
		</div>
	)
}

export const ArrowDown = () => {
	return (
		<svg className={classes.ArrowDown} viewBox="0 0 19 11" width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg" ><path d="M1 1l8.077 8.077L17.154 1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round"></path></svg>
	)
}

export const ErrorMsg = (props: {text:string, closeMsg?: any}) => (
	<div className={classes.ErrorMsg} >
		<p>{props.text}</p>
		<p className={classes.Close} onClick={() => props.closeMsg(false)}>x</p>
	</div>
)

export const SuccessMsg = (props: {text:string, closeMsg?: any}) => (
	<div className={`${classes.SuccessMsg} ${classes.ErrorMsg}`} >
		<p>{props.text}</p>
		<p className={classes.Close} onClick={() => props.closeMsg(false)}>x</p>
	</div>
)


export const AndroidIcon = () => (<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path></svg>)
export const AppleIcon = () => (<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5-34.9-50-87.7-77.5-157.3-82.8-65.9-5.2-138 38.4-164.4 38.4-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8 23.8 68.2 109.6 235.3 199.1 232.6 46.8-1.1 79.9-33.2 140.8-33.2 59.1 0 89.7 33.2 141.9 33.2 90.3-1.3 167.9-153.2 190.5-221.6-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7-44.8 2.6-96.6 30.5-126.1 64.8-32.5 36.8-51.6 82.3-47.5 133.6 48.4 3.7 92.6-21.2 129-63.7z"></path></svg>)