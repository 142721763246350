import axios from 'axios';
import React, { useState } from 'react';
import { login } from '../../cognito/redirect';
import Config from '../../config';
import classes from './InvestorRelationsForm.module.scss'
import {
	CButton,
	CCheckBox,
	CInput,
	CLink,
	CSelect,
	CTextArea,
	ErrorMsg,
	SuccessMsg } from './UIElements/UIElements';

const InvestorRelationsForm = () => {
	// the values for the form inputs can be access through <STATE_NAME.value>
	const [title, setTitle] = useState({ required: true, value: '', error: false });
	const [interest, setinterest] = useState({ required: true, value: '', error: false });
	const [fn, setFN] = useState({ required: true, value: '', error: false });
	const [ln, setLN] = useState({ required: true, value: '', error: false });
	const [company, setCompany] = useState({ required: true, value: '', error: false });
	const [country, setCountry] = useState({ required: true, value: '', error: false });
	const [email, setEmail] = useState({ required: true, value: '', error: false });
	const [text, setText] = useState({ required: false, value: '', error: false });
	const [agree, setAgree] = useState(false);

	// Error management and validation
	const [requiredError, setRequiredError] = useState(false);
	const [agreeError, setAgreeError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [axiosError, setAxiosError] = useState(false);

	const SubmitHandler = (e:any) => {
		e.preventDefault()
		let error = false;
		if (fn.required && fn.value === '') {
			setFN({...fn, error: true});
			error = true;
		}
		if (ln.required && ln.value === '') {
			setLN({...ln, error: true});
			error = true;
		}
		if (title.required && title.value === '') {
			setTitle({...title, error: true});
			error = true;
		}
		if (interest.required && interest.value === '') {
			setinterest({...interest, error: true});
			error = true;
		}
		if (company.required && company.value === '') {
			setCompany({...company, error: true});
			error = true;
		}
		if (country.required && country.value === '') {
			setCountry({...country, error: true});
			error = true;
		}
		if (email.required && email.value === '') {
			setEmail({...email, error: true});
			error = true;
		}
		if (text.required && text.value === '') {
			setText({...text, error: true});
			error = true;
		}

		if (error)
			setRequiredError(true)
		if (!agree)
			setAgreeError(true)
		if (agree && !error) {
			// when the validiation and errors are passed sucessfuly
			setRequiredError(false)
			setAgreeError(false)
			setAxiosError(false)

			// call send post request here
			const newInvestor = {
				salutation: title.value,
				firstname: fn.value,
				lastname: ln.value,
				email: email.value,
				interest: interest.value,
				company: company.value,
				country: country.value,
				text: text.value,
			}

			// test link that returns an error,
			axios.post(`${Config.baseUrl}/investors/sign-in`, newInvestor)
			.then(() => setSuccess(true))
			.catch(() => setAxiosError(true))
		}
	}

	return (
		<div className={classes.InvestorRelationsForm}>
			<div className={classes.InvestorRelationsFormWrapper}>
				<div className={classes.Section1} >
					<h1>Investor Relations</h1>
					<h4>If you don't yet have an account, please fill in the form below.</h4>
					{title.value}
					<form onSubmit={SubmitHandler} >
						{requiredError && <ErrorMsg text="You didn't fill all required fields!" closeMsg={setRequiredError} />}
						{axiosError && <ErrorMsg text="Internal error accured! Please try later" closeMsg={setAxiosError} />}
						{success && <SuccessMsg text="Sent successfully !" closeMsg={setSuccess} />}
						<CSelect name='title' label='Title' options={[
							{name: 'Mrs', value: 'Mrs'},
							{name: 'Mr', value: 'Mr'}
						]} changeHandler={setTitle} state={title} required={title.required}/>
						<CInput name='firstname' type='text' label='First Name' changeHandler={setFN} state={fn}/>
						<CInput name='lastname' type='text' label='Last Name' changeHandler={setLN} state={ln}/>
						<CInput name='email' type='email' label='E-Mail' changeHandler={setEmail} state={email}/>
						<CSelect name='interest' label='Your interest' options={[
							{name: 'Existing Investor', value: 'Existing Investor'},
							{name: 'Potential Investor', value: 'Potential Investor'},
							{name: 'Research Analyst', value: 'Research Analyst'},
							{name: 'Credit Analyst', value: 'Credit Analyst'},
							{name: 'Others', value: 'Others'},
						]} changeHandler={setinterest} state={interest}/>
						<CInput name='company' type='text' label='Company' changeHandler={setCompany} state={company}/>
						<CInput name='country' type='text' label='Country' changeHandler={setCountry} state={country}/>
						<CTextArea name='text' placeholder='Your Text here' limit={250} changeHandler={setText} state={text}/>
						<label>Disclaimer<span>*</span></label>
						{agreeError && <ErrorMsg text="You have to agree to our disclamer!" closeMsg={setAgreeError} />}
						<CCheckBox name='agree' changeHandler={setAgree} state={agree}>
							<p><span style={{color: 'black'}} onClick={() => setAgree(!agree)} >I agree to the </span> <a href='/disclaimer' style={{color: '#ff3c28'}} >disclaimer</a> and the <a href='/policy' style={{color: '#ff3c28'}} >privacy policy</a>.</p>
						</CCheckBox>
						<label><span>*</span>Mandatory field</label>
						<CButton label='Submit' name='submit' type='submit' buttonStyle='primary' />
					</form>
				</div>
				<div className={classes.Section2} >
					<h2>Already have an account? Sign-in here</h2>
					<CLink to='#' label='Login' buttonStyle='primary' onClick={() => login()} />
				</div>
			</div>
		</div>
	)
}

export default InvestorRelationsForm;
