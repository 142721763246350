import React from 'react';
import { Helmet } from 'react-helmet';
import { isAuthenticated } from '../../cognito';
import Bond from '../../components/Bond/Bond';
import FinancialCalendar from '../../components/FinancialCalendar/FinancialCalendar';
import InvestorCard from '../../components/InvestorCard/InvestorCard';
import InvestorRelationHero from '../../components/InvestorRelationHero/InvestorHero';
import InvestorRelationsForm from '../../components/InvestorRelationsForm/InvestorRelationsForm';
import {
  AndroidIcon,
  AppleIcon,
} from '../../components/InvestorRelationsForm/UIElements/UIElements';
import RatingTable from '../../components/RatingTable/RatingTable';
import externalLinks from '../../utils/externalLinks';
import Publications from '../../components/Publications/Publications';
import FactSheet from '../../components/FactSheet/FactSheet';
import Footer from '../../components/Footer/Footer';
import classes from './index.module.scss';
import { factSheet2023, FinancialCalendar2024 } from '../../constants/constants';

const InvestorsPage = () => (
  <div id="investor-container">
    <Helmet title="HSE - Investors" />
    <InvestorRelationHero />

    <div id="investor-wrapper">
      {!isAuthenticated() && <InvestorRelationsForm />}

      {isAuthenticated() && (
        <div className="wrapper">
          <div className={classes.MainTitle}>
            <h2 className={classes.Heading}>Investor Relations</h2>
          </div>
          <p className={classes.HSEDesc}>
            HSE is a leading provider in the live commerce sector in Europe. As a media platform, it
            offers the most entertaining form of shopping. On TV, in the online shop, via the
            top-rated, award-winning
            <sup>
              <a href="#award">[1]</a>
            </sup>{' '}
            app
            <sup>
              <a href="#appstore">[2]</a>
            </sup>{' '}
            and on social media, the company presents and sells a curated product range from the
            areas of fashion, jewellery, beauty, wellness, household, and home and living – live,
            across channels and interactively.
          </p>
          <FactSheet
            factSheet={factSheet2023}
            year="2023"
            description="*acc. to audited financial statement as of Dec 31, 2023"
          />
          <Bond />
          <Publications />
          <FinancialCalendar events={FinancialCalendar2024} />

          <RatingTable />
          <InvestorCard />
          <section aria-label="Footnotes" className={classes.Footnotes}>
            <div id="award">
              <p>[1]</p>
              <p>Eyes & Ears Award 2020</p>
            </div>
            <div id="appstore">
              <p>[2]</p>
              <div>
                <a
                  href="https://apps.apple.com/de/app/hse-here-shopping-entertains/id1016812570"
                  target="blank"
                >
                  <AndroidIcon /> Get it on Apple store
                </a>
                <br />
                <a
                  href="https://play.google.com/store/apps/details?id=de.hse24&hl=de&gl=US"
                  target="blank"
                >
                  <AppleIcon /> Get it on Google play store
                </a>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>

    <Footer
      links={[
        {
          text: 'ONLINESHOP',
          href: externalLinks.onlineshop,
          target: '_self',
        },
        {
          text: 'KARRIERE',
          href: externalLinks.karriere,
          target: '_self',
        },
        {
          text: 'Kontakt',
          href: externalLinks.kontakt,
          target: '_self',
        },
        {
          text: 'Investor Relations',
          href: externalLinks.investor,
          target: '_self',
        },
      ]}
      socialMediaLinks={[
        {
          icon: {
            src: require('assets/icons/facebook.svg'),
            alt: 'Facebook page',
          },
          href: externalLinks.facebook,
        },
        {
          icon: {
            src: require('assets/icons/instagram.svg'),
            alt: 'Instagram profile',
          },
          href: externalLinks.instagram,
        },
        {
          icon: {
            src: require('assets/icons/pinterest.svg'),
            alt: 'Pinterest profile',
          },
          href: externalLinks.pinterest,
        },
        {
          icon: {
            src: require('assets/icons/youtube.svg'),
            alt: 'Youtube channel',
          },
          href: externalLinks.youtube,
        },
        {
          icon: {
            src: require('assets/icons/linkedin.svg'),
            alt: 'Linkedin profile',
          },
          href: externalLinks.linkedin,
        },
        {
          icon: {
            src: require('assets/icons/xing.svg'),
            alt: 'Xing profile',
          },
          href: externalLinks.xing,
        },
      ]}
      mobileAppsLinks={[
        {
          icon: {
            src: require('assets/images/apple-store.png'),
            alt: 'ios app',
          },
          href: externalLinks.ios_app,
        },
        {
          icon: {
            src: require('assets/images/google-play-store.png'),
            alt: 'android app',
          },
          href: externalLinks.android_app,
        },
      ]}
      subFooterContent={{
        legalLinks: [
          {
            text: 'Impressum',
            href: externalLinks.impressum,
          },
          {
            text: 'Datenschutz',
            href: externalLinks.datenschutz,
          },
        ],
        copyrightText: `Alle Rechte vorbehalten © HSE ${new Date().getFullYear()}`,
      }}
    />
  </div>
);

export default InvestorsPage;
