import React from 'react';
import { defaultNavbarLinks } from '../../constants/constants';
import Navbar from '../Hero/Navbar/Navbar';

import styles from './InvestorHero.module.scss';

const InvestorHero = () => {
  return (
    <div className={styles.wrapper}>
      <Navbar sticky={false} links={defaultNavbarLinks} />
    </div>
  );
}

export default InvestorHero;
