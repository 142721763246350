import classNames from 'classnames';
import React from 'react';
import { PUBS2022, PUBS2023, PUBS2024 } from '../../constants/constants';
import classes from './Publications.module.scss';

const pubs = [
  {
    date: 'FY 2021',
    pub: 'FY 2021 Investor call (Dial-in information)',
    file: require('assets/documents/FY/FY_2021_Investor_call_Dial-in information.pdf'),
    divider: true,
  },
  {
    date: 'FY 2021',
    pub: 'FY 2021 Announcement Financial Results',
    file: require('assets/documents/FY/FY_2021_Announcement_Financial_Result.pdf'),
    divider: true,
  },
  {
    date: '	FY 2021',
    pub: ' FY 2021 Investor Presentation',
    file: require('assets/documents/FY/FY_2021_Investor_Presentation.pdf'),
    divider: true,
  },
  {
    date: 'FY 2021',
    pub: 'FY 2021 Financial Reporting (Audit Report)',
    file: require('assets/documents/FY/FY_2021_Financial Reporting_Audit_Report.pdf'),
    divider: true,
    spacing: true,
  },

  {
    date: 'Q3-2020/2021',
    pub: 'Q3-2021 Announcement Financial Results/Investor Call',
    file: require('assets/documents/Q3/Q3-2021_announcement_financial_results_investor_call.pdf'),
  },
  {
    date: 'Q3-2020/2021',
    pub: 'Q3-2021 Investor Call (Dial-in information)',
    file: require('assets/documents/Q3/Q3-2021_Investor_Call_Dial-in_information.pdf'),
  },
  {
    date: 'Q3-2020/2021',
    pub: 'Q3-2021 Financial Results (Investor update)',
    file: require('assets/documents/Q3/Q3-2021_Financial_Results_Investor_Updated.pdf'),
  },
  {
    date: '9M-2020/2021',
    pub: '9M-2020/2021 Bondholder Reporting',
    file: require('assets/documents/Q3/9M-2020_2021_Bondholder_Reporting.pdf'),
    spacing: true,
  },
  {
    date: 'Q2-2020/2021',
    pub: 'Q2-2021 Announcement Financial Results/Investor Call',
    file: require('assets/documents/Q2/HSE_Q2-2021_Announcement_Fin Results_Investor Call.pdf'),
  },
  {
    date: 'Q2-2020/2021',
    pub: 'Q2-2021 Financial results (Investor update)',
    file: require('assets/documents/Q2/HSE_Q2-2021_Financial_results_(Investor update).pdf'),
  },

  {
    date: '6M-2020/2021',
    pub: '6M-2020/2021 Bondholder Reporting',
    file: require('assets/documents/Q2/HSE_6M_2020_2021_Bondholder_Reporting.pdf'),
  },

  {
    date: '3M-2020/2021',
    pub: '3M-2020/2021 Bondholder Reporting',
    file: require('assets/documents/Q2/3M_2020_2021_Bondholder_Reporting.pdf'),
  },

  {
    date: 'FY-2021',
    pub: 'FY-2021 HSE press release',
    file: require('assets/documents/FY/FY2021_HSE_press_release.pdf'),
  },
  {
    date: '',
    pub: 'Moody`s issuer comment (11 March 2022)',
    file: require('assets/documents/Issuer_Comment _HSE_Finance.pdf'),
  },
];

interface PublicationType {
  date: string;
  pub: string;
  file: any;
  divider?: boolean;
  spacing?: boolean;
}

const Publication = (props: { pub: PublicationType }) => {
  return (
    <div className={classNames(classes.publication, { [classes.spacing]: props.pub.spacing })}>
      {props.pub.date && <p>{props.pub.date}</p>}
      {props.pub.divider && <hr />}
      <a href={props.pub.file} target="_blank">
        <p>{props.pub.pub}</p>
        {props.pub.file && <img src={require('assets/icons/document.svg')}/>}
      </a>
    </div>
  );
};

const Publications = () => {
  return (
    <div className={classes.publications} id="publications">
      <h2>Publications</h2>
      <h4>Reports & Presentations</h4>
      <h4>2024</h4>
      <div className={classes.list}>
        {PUBS2024.map((pub, key) => (
          <Publication key={key} pub={pub} />
        ))}
      </div>
      <h4>2023</h4>
      <div className={classes.list}>
        {PUBS2023.map((pub, key) => (
          <Publication key={key} pub={pub} />
        ))}
      </div>
      <h4>2022</h4>
      <div className={classes.list}>
        {PUBS2022.map((pub, key) => (
          <Publication key={key} pub={pub} />
        ))}
      </div>
      <h4>2021</h4>
      <div className={classes.list}>
        {pubs.map((pub, key) => (
          <Publication key={key} pub={pub} />
        ))}
      </div>
    </div>
  );
};

export default Publications;
