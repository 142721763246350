import React from 'react';
import classes from './FactSheet.module.scss';

interface Props {
  year?: string;
  factSheet: Array<{ label: string; value: string }>;
  description: string;
}

const FactSheet = ({ year, factSheet, description }: Props) => {
  return (
    <div className={classes.FactSheet} id="fact-sheet">
      <h2>Fact Sheet for fiscal year {year}</h2>
      <table>
        <thead>
          {factSheet.map(({ label, value }, index) => {
            return (
              <tr key={index}>
                <td>{label}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </thead>
      </table>
      <p>{description}</p>
    </div>
  );
};

export default FactSheet;
