import React, { useState } from 'react';
import classes from './Bond.module.scss';

declare type IssuerType = {
	id: number,
	header: string,
	issuer: string,
	size:  string,
	maturity: string,
	coupon: string,
	inPayDate: string,
	rating: string,
	govLaw: string,
	listing: string,
	isin: Array<string>,
}

const issuers = [
	{
		id: 0,
		header: 'A. 5.625% Senior Secured Notes due 2026',
		issuer: 'HSE Finance S.à r.l.',
		size: '€380,000,000',
		maturity: 'October 15, 2026',
		coupon: '5.625% per annum',
		inPayDate: 'May 15 and November 15 (commencing on November 15, 2021), except for last interest payment date, which will be October 15, 2026',
		rating: 'B2 by Moody’s and B by S&P',
		govLaw: 'New York',
		listing: 'The International Stock Exchange (pending)',
		isin: ['XS2337308238', 'XS2337308311'],
	},
	{
		id: 2,
		header: 'B. Floating Rate Senior Secured Notes due 2026',	
		issuer: 'HSE Finance S.à r.l.',
		size: '€250,000,000',
		maturity: 'October 15, 2026',
		coupon: 'Three-month EURIBOR (with a floor of 0%) plus 5.75% per annum, reset quarterly',
		inPayDate: 'February 15, May 15, August 15 and November 15 (commencing on August 15, 2021), except for last interest payment date, which will be October 15, 2026',
		rating: 'B2 by Moody’s and B by S&P',
		govLaw: 'New York',
		listing: 'The International Stock Exchange (pending)',
		isin: ['XS2337308741', 'XS2337309806'],
	},
]

const Issuer = (props: {issuer: IssuerType}) => {
	return (
		<div className={classes.Issuer} >
			<table>
				<tbody>
					<tr>
						<td>Issuer</td>
						<td>{props.issuer.issuer}</td>
					</tr>
					<tr>
						<td>Size</td>
						<td>{props.issuer.size}</td>
					</tr>
					<tr>
						<td>Maturity</td>
						<td>{props.issuer.maturity}</td>
					</tr>
					<tr>
						<td>Coupon</td>
						<td>{props.issuer.coupon}</td>
					</tr>
					<tr>
						<td>Interest payment dates</td>
						<td>{props.issuer.inPayDate}</td>
					</tr>
					<tr>
						<td>Governing law</td>
						<td>{props.issuer.govLaw}</td>
					</tr>
					<tr>
						<td>Rating*</td>
						<td>{props.issuer.rating}</td>
					</tr>
					<tr>
						<td>Listing</td>
						<td>{props.issuer.listing}</td>
					</tr>
					{
						props.issuer.isin.map((item, key) => (
							<tr key={key} >
								<td>ISIN (Regulation S Notes)</td>
								<td>{item}</td>
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	)
}

const Toggle = (props: {issuer:IssuerType, changeHandler: any, currentTab: number}) => {
	return (
		<div 
			className={[props.currentTab === props.issuer.id && classes.Active, classes.Toggle].join(' ')} 
			onClick={() => props.changeHandler(props.issuer.id)} >
			{props.issuer.header}
		</div>
	)
}

const Bond = () => {

	const [currentTab, setCurrentTab] = useState(0);

	return (
		<div className={classes.Bond} id="bond" >
			<h2>Bond</h2>
			<div className={classes.Toggles} >
			{
				issuers.map((issuer, key) => 
				<Toggle 
					issuer={issuer} 
					changeHandler={setCurrentTab} 
					currentTab={currentTab} 
					key={key} />)
			}
			</div>
			<div className={classes.Body} >
			{issuers.map((issuer, key) => {
				return currentTab === issuer.id ? 
					<Issuer issuer={issuer} key={key} /> 
				: null
			})}
			<p className={classes.Note} >Note: A securities rating is not a recommendation to buy, sell or hold securities and may be subject to revision or withdrawal at any time.</p>
			</div>
		</div>
	)
}

export default Bond;
